.checkboxes{
    margin-left: 30px;
    flex: 0 0 75%;
}

.logoDesktop{
    display: block!important;
}

.logoMobile{
    display: none;
}

@media only screen and (max-width: 764px) {
    .checkboxes{
        margin-left: 0px;
        flex: auto;
    }
    .logoMobile{
        display: block;
    }
    .logoDesktop{
        display: none!important;
    }
}

@media only screen and (max-width: 600px) {
    .logoMobile{
        display: block;
    }
    .logoDesktop{
        display: none!important;
    }
}