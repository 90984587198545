.footerImage{
  float: center;
  justify-content: center;
  margin-bottom: 20px;
}
.footerText{
  display: inline-block;
  width: calc(100% - 100px);
}

.myFooter > div:nth-of-type(2){
  justify-content: center;
}
.footerImageImg{
  height: 30px;
  width: auto;
}
@media only screen and (max-width: 600px) {
  .footerImage{
    float: none;
    text-align: center;
  }
  .footerText{
    width: 100%;
  }

  .myFooter > div:nth-of-type(1){
    flex: 0 0 100%;
    text-align: left;
  }
  .myFooter > div:nth-of-type(2){
    justify-content: left;
  }
  .footerImageImg{
    height: 26px;
  }
}

.myFooter{
  /*position: sticky;
  top: calc(100vh - 50px);*/
  position: fixed;
  bottom: 0px;
  background-color: #243680;
  font-weight: bold;
  width: 100vw;
  padding: 10px 30px;
  font-size: 14px;
  display: flex;
  flex-flow: wrap;
  text-decoration: none;
  color: white;
  gap: 10px;
  align-items: start;
}
.myFooter > div{
  flex: 1 1;
}

.a, .myFooter a{
  color: white;
  text-decoration: underline;
  cursor: pointer;
}