.banner{
    width: 100%;
    height: auto;
    cursor: pointer;
}

.banner img{
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 1400px){
    .banner{
        width: 100%;
        height: 300px;
    }
    .banner img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}