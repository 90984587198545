.eventList{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0px 30px;
}

@media screen and (min-width: 700px){
    .eventList{
        justify-content: left;
    }
}

.label{
    flex: 0 0 100%;
    margin: 0px 10px 10px;
    /*text-transform: uppercase;*/
}

.viewMore{
    padding: 13px 36px;
    background-color: #245780;
    color: white;
    cursor: pointer;
    margin-bottom: 15px;
    display: inline-block;
    font-size: 16px;
}