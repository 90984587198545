.myPlayer{
    width: 100%; 
}
.myPDFPlayer{
    height: calc(100vh - 350px);
}

@media only screen and (min-width: 600px) {
    .myPlayer{
        height: calc(100vh - 230px);
    }
}

@media only screen and (min-width: 1024px) {
    .myPlayer{
        height: calc(100vh - 170px);
    }
}
@media only screen and (max-width: 1025px) {
    .myPlayer{
        position: sticky;
        top: 0px;
        z-index: 1;
    }
}

@media only screen and (max-height: 450px) {
    .myPlayer{
        height: calc(100vh - 92px);
    }
}