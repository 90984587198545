@import "../../styles/colors";

$side-menu-width: 230px;

#side-panel{
    width: $side-menu-width;
    height: 100%;
    border-radius: 0px;
    position: fixed;
    left: 0;
    top: 0;
}

.padding-left{
    padding-left: $side-menu-width;
}

.menu-item {
    font-weight: bold;
    cursor: pointer;
    color: #fff;
    border: {
        bottom: 2px solid  #fff !important;
        // top: 1px solid  #fff !important;
    }
}

#logout {
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}

#title {
    text-align: center;
    font: {
        size: 40px;
        // weight: bold;
    }
}


#subtitle {
    text-align: center;
    font: {
        size: 35px;
        // weight: bold;
    }
}