.popup-bg{
  background-color: #0000004d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.popup{
  background-color: white;
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  /*top: 30px;
  bottom: 30px;*/
  top: 50%;
  transform: translate(0, -50%);
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  padding: 11px 60px 30px 60px;
  font-size: 24px;
}

@media only screen and (max-width: 600px) {
  .popup{
    font-size: 16px;
    padding: 15px 15px;
  }
}

@media only screen and (max-height: 600px) {
  .popup{
    font-size: 16px;
    padding: 15px 15px;
  }
}
