.button{
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    display: inline-block;
    
    .text{
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%);
        font-size: 12px;
        margin-top: 5px;
        font-weight: normal;
        width: 100%;
        text-align: center;
    }
}

.searchPopup{
    width: 400px;
    max-width: 95%;
    background-color: white;
    padding: 25px;
}

.searchBg{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10000;
    background-color: #0000009e;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.freeText{
    width: 100%;
}

.submit{
    color: white;
    display: inline-block;
    padding: 5px 15px;
    margin-top: 15px;
    border-radius: 5px;
    cursor: pointer;
}