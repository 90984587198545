.myToggle .react-toggle-track-check{
    top: 10px;
}
.myToggle .react-toggle-track-x{
    top: 10px;
}

.myToggle.react-toggle--checked .react-toggle-track{
    background-color: #e46b23;
}
.myToggle.react-toggle--checked:hover .react-toggle-track:hover{
    background-color: #ca5d1e;
}
.myToggle.react-toggle--checked .react-toggle-thumb{
    border-color: #e46b23;
}

.react-toggle--checked:hover .react-toggle-track {
    background-color: #ca5d1e!important;
}

.select{
    width: 170px;
}

@media only screen and (max-width: 550px){
    .select{
        width: 140px;
    }
}
@media only screen and (max-width: 348px){
    .select{
        width: 110px;
    }
}