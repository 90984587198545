@import '../../../../styles/_colors';

.header {
    // text-decoration: underline;
    font-size: 25px;
    //font-weight: bold;
}
/*.row{
    justify-content: flex-start!important;
}*/
