.sharePopup{
    width: 500px;
    max-width: 95%;
    background-color: white;
    padding: 25px;
}

.shareBg{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10000;
    background-color: #0000009e;
    display: flex;
    justify-content: center;
    align-items: center;
}

.network{
    display: inline-block;
    padding: 5px;
}

.networkContainer{
    overflow: scroll;
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
}

.urlInput{
    width: 100%;
    background-color: #f9f9f9;
    padding: 5px 15px;
    border: 1px solid #e0e0e0;
}