.topText{
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
  flex: 0 1 auto;
  margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
  .topText{
    font-size: 12px;
  }
}
