.list{
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    padding-bottom: 12px;
}

.item{
    padding: 5px 10px;
    background-color: #0000000d;
    border: 1px solid #0000001a;
    border-radius: 16px;
    margin: 0px 5px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.5s cubic-bezier(0.05, 0, 0, 1);
}

.item:hover{
    background-color: #0000001a;
}

.item.selected{
    color: #fff!important;
    background-color: #e46b23;
}