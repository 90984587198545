@import '../../../../styles/colors';


.label {
    font-size: 16px !important;
    padding: 0 !important;
    // text-align: end;
}

.input {
    height: 35px !important;
    outline: none !important;
    box-shadow:none !important;
    font-size: 16px !important;
    margin: 0 !important;
    border: {
        width: 2px !important;
        style: solid !important;
        radius: 5px !important;
    }


    &-date {
        @extend .input;
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &-append-icon {
        @extend .input;
        border-left: none !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    &-file {
        @extend .input;
        background-color: rgb(224, 224, 224);
        cursor: pointer;
    }

    &-invalid {
        @extend .input;
        border-color: red !important;
    }
}


#img-preview {
    width: 120px;
    height: 80px;
    border: {
        width: 2px !important;
        style: solid !important;
        radius: 5px !important;
    }
    cursor: pointer;
}

#img-modal-preview {
    width: 100%;
    max-height: 90vh;
}

.html-editor {
    border: {
        width: 1px !important;
        style: solid !important;
        radius: 5px !important;
    }

    width: 100%;
    &-invalid {
        @extend .html-editor;
        border-color: red !important;
    }
}