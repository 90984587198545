.mobile{
  display: none;
}
.desktop{
  display: block;
}
.header{
  width: 100%;
  position: relative;
  padding: 0px 150px 0px 30px;
}
.headerTitle{
  line-height: 1em;
  color: white;
  font-weight: bold;
  font-size: 24px;
  top: 50%;
  position: absolute;
  transform: translate(0, -50%);
  left: 150px;
  right: 140px;
}


@media only screen and (max-width: 600px) {
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
}
 