.search{
    margin-top: -30px;
}

.advancedSearch{
    float: right;
    margin-top: -2px;
    margin-left: 10px;
}

@media only screen and (max-width: 375px){
    .search{
        margin-top: 10px;
    }
}

.sortBy{
    float: right;
    margin-top: -7px;
}